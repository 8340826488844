<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=1 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">企业资格认证<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb resetpwd">
                  <el-form v-if="isauthresult==1" :model="form" :label-position="labelPosition" :rules="rules"
                    label-width="100px" class="elform" ref="elForm">

                    <el-form-item class="formL" label="资质名称" prop="CertType">
                      <el-input v-model="form.CertType" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item class="formL" label="证书编号" prop="CertNO">
                      <el-input v-model="form.CertNO"></el-input>
                    </el-form-item>
                    <el-form-item label="资质等级" prop="QualificationGradeId">
                      <el-select placeholder="请选择资质等级" @change="onQualificationGradeIdchange"
                        v-model="form.QualificationGradeId">
                        <el-option value="" label="">请选择</el-option>
                        <el-option v-for="item in companycertgradedata" :key="item.id" :label="item.data_title"
                          :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="发证日期" prop="IssueDate">
                      <el-col :span="11">
                        <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
                          v-model="form.IssueDate" style="width: 100%;">
                        </el-date-picker>
                      </el-col>
                    </el-form-item>
                    <el-form-item class="" label="有效期自" prop="EffectiveDate">
                      <el-col :span="11">
                        <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
                          v-model="form.EffectiveDate" style="width: 100%;">
                        </el-date-picker>
                      </el-col>
                    </el-form-item>
                    <el-form-item class="formL " label="有效期至" prop="ExpiryDate">
                      <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
                        v-model="form.ExpiryDate" style="width: 164px;margin-right:10px">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item class="formL" label="备案登记" prop="RegisterRecord">
                      <el-input v-model="form.RegisterRecord"></el-input>
                    </el-form-item>
                    <el-form-item label="资格证书" prop="CertImg">
                      <el-upload style="float:left;" :multiple="false" class="upload-demo" :headers="myHeaders"
                        list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                        :action="uploadUrl" name="file" accept="image/png,image/gif,image/jpg,image/jpeg"
                        :file-list="fileList" :on-success="busSuccess" :on-error="busError" :on-change="busChange">
                        <i class="el-icon-plus"></i>
                      </el-upload>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="onsubmitForm('elForm')">提交</el-button>
                    </el-form-item>
                  </el-form>
                  <el-form v-if="isauthresult!=1" :model="form" :label-position="labelPosition" :rules="rules"
                    label-width="100px" class="elform" ref="elForm">
                    <el-form-item v-if="isauthresult!=1" label="企业照片" prop="CertImg">
                      <el-upload style="float:left;" :multiple="false" class="upload-demo" :headers="myHeaders"
                        list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                        :action="uploadUrl" name="file" accept="image/png,image/gif,image/jpg,image/jpeg"
                        :file-list="fileList" :on-success="busSuccess" :on-error="busError">
                        <i class="el-icon-plus"></i>
                      </el-upload>

                    </el-form-item>
                    <el-form-item>
                      <el-button @click="onsubmitForm('elForm')">提交</el-button>
                    </el-form-item>

                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
    <el-dialog :visible.sync="dialogimgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import {
  companycertgradelist,
  companycertauth,
  companycertlist,
  companycertisauth,
} from '@/api/user';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      dialogImageUrl: '',
      businessend: '',
      dialogVisible: false,
      dialogimgVisible: false,
      labelPosition: 'right',
      firstShow: true,
      show: false,
      height: '600',
      companycertgradedata: [],
      fileList: [],
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      isauthresult: 1,
      form: {
        CompanyId: 0,
        CertTypeId: 0,
        CertNO: '',
        IssueDate: '',
        EffectiveDate: '',
        ExpiryDate: '',
        QualificationGradeId: 0,
        RegisterRecord: '',
        CertImg: '',
        CertType: '',
      },
      rules: {
        CertTypeId: [{ required: true, message: '请输入资质名称' }],
        CertNO: [{ required: true, message: '请输入证书编号' }],
        IssueDate: [{ required: true, message: '请输入发证日期' }],
        EffectiveDate: [{ required: true, message: '请输入有效期自' }],
        ExpiryDate: [{ required: true, message: '请输入有效期至' }],
        QualificationGradeId: [{ required: true, message: '请选择资质等级' }],
        RegisterRecord: [{ required: true, message: '请输入备案登记' }],
        CertImg: [{ required: true, message: '请上传资格证书' }],
      },
    };
  },
  methods: {
    onQualificationGradeIdchange(item) {
      this.form.QualificationGradeId = item.id;
    },
    onsubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var PhotoUrlArray = new Array();
          this.fileList.forEach(function (item, index) {
            PhotoUrlArray.push(item.response.result.fileToken);
          });
          this.form.CertImg = PhotoUrlArray.join(',');
          companycertauth(this.form).then((res) => {
            if (res.data.code === 1) {
              this.$message({
                message: '恭喜你，提交成功',
                type: 'success',
              });
              setTimeout(function () {
                window.location.href="/user/enterpriselist"
              }, 300);
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    oncompanycertgradelist() {
      var that = this;
      companycertgradelist().then((res) => {
        if (res.data.code === 1) {
          that.companycertgradedata = res.data.result;
        }
      });
    },
    oncompanycertisauth() {
      var that = this;
      var data = {
        companyId: that.form.CompanyId,
        certTypeId: that.form.CertTypeId,
      };
      console.log(data);
      companycertisauth(data).then((res) => {
        if (res.data.code === 1) {
          that.isauthresult = res.data.result;
        }
      });
    },
    oncompanycertlist() {
      var that = this;
      var data = { companyId: that.form.CompanyId };
      companycertlist(data).then((res) => {
        if (res.data.code === 1) {
          res.data.result.forEach(function (item, index) {
            if (item.id == that.form.CertTypeId) {
              that.form.CertType = item.data_title;
            }
          });
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgVisible = true;
    },
    busSuccess(response, file, fileList) {
      var that = this;
      this.fileList = fileList;
      console.log(fileList);
      if (response.code == 1) {
        this.form.CertImg = response.result.fileToken;
      }
    },
    busError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    locatefal() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    addlocation() {
      this.dialogVisible = true;
    },
  },
  created() {
    this.form.CompanyId = this.$route.query.companyid;
    this.form.CertTypeId = this.$route.query.certtypeid;
    this.oncompanycertisauth();
    this.oncompanycertgradelist();
    this.oncompanycertlist();
  },
  mounted() {
    screenLeft;
    const self = this;
  },
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
.accountset >>> form.elform .formaddress .el-input {
  width: 220px;
  margin-right: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.showbusinessend {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  margin-left: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.chooseaddress {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.accountset >>> form.elform .small .el-form-item__label {
  line-height: 20px;
}
.accountset .account-nav li.cur i.detail {
  background-position: 0 -142px;
  background-image: url('../../assets/img/account-ico.png');
}
</style>
